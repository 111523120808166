









































































































































































import { appName, merchantURL, appUrl } from "@/env";
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import { IUserProfile } from "@/interfaces";
import { readUserProfile } from "@/store/main/getters";
import { dispatchSalesReport } from "@/store/admin/actions";
import _ from "lodash";
import moment, { duration } from "moment-timezone";
import Apply from "../../Apply.vue";
import EditUser from "./EditUser.vue";
import UploadUserDoc from "./UploadUserDoc.vue";
import EditAddress from "./EditAddress.vue";
import AdminViewUser from "./AdminViewUser.vue";
import JsonCSV from "vue-json-csv";
@Component({
  components: {
    Apply,
    JsonCSV,
    AdminViewUser,
    EditUser,
    UploadUserDoc,
    EditAddress,
  },
})
export default class SalesReport extends Vue {
  public dialog = false;

  public onlyAdmin = false;
  public onlyPaid = false;

  public headers() {
    return [
      {
        text: "First Name",
        sortable: true,
        value: "user.first_name",
        align: "left",
      },
      {
        text: "Last Name",
        sortable: true,
        value: "user.last_name",
        align: "left",
      },
      {
        text: "Splynx CID",
        sortable: true,
        value: "user.splynx_cid",
        align: "left",
      },
      {
        text: "Phone Number",
        sortable: true,
        value: "user.phone",
        align: "left",
      },

      {
        text: "Email",
        sortable: true,
        value: "user.email",
        align: "left",
      },
      {
        text: "Condo Building",
        sortable: true,
        value: "building.project_name",
        align: "left",
      },
      {
        text: "Condo Code",
        sortable: true,
        value: "building.condo_code",
        align: "left",
      },
      {
        text: "Unit Number",
        sortable: true,
        value: "service_address.unit",
        align: "left",
      },
      {
        text: "Num Floors",
        sortable: true,
        value: "building.num_floors",
        align: "left",
      },
      {
        text: "Num Units",
        sortable: true,
        value: "building.num_units",
        align: "left",
      },
      {
        text: "Referral Source",
        sortable: true,
        value: "user.referral",
        align: "left",
      },
      {
        text: "Referral Comment",
        sortable: true,
        value: "user.referral_other",
        align: "left",
      },
      {
        text: "Referred by CID",
        sortable: true,
        value: "user.referral_cid",
        align: "left",
      },
      {
        text: "RAF Invites Sent",
        sortable: true,
        value: "referrals",
        align: "left",
      },
      {
        text: "RAF Signups",
        sortable: true,
        value: "signups",
        align: "left",
      },
      {
        text: "Referred CID (UNPAID)",
        sortable: true,
        value: "referred_signups",
        align: "left",
      },
      {
        text: "Referred CID (PAID)",
        sortable: true,
        value: "referred_paid_signups",
        align: "left",
      },
      {
        text: "Market Segment",
        sortable: true,
        value: "market_segment.segment",
        align: "left",
      },
      {
        text: "Market Segment Desc.",
        sortable: true,
        value: "market_segment.description",
        align: "left",
      },
      {
        text: "Plan",
        sortable: true,
        value: "user.service_name",
        align: "left",
      },
      {
        text: "Is Contract Active",
        sortable: true,
        value: "user.is_contract_active",
        align: "left",
        filter: (value) => {
          if (this.onlyActive && value) {
            return true;
          } else {
            if (!this.onlyActive && !value) {
              return true;
            }
            return false;
          }
        },
      },
      {
        text: "Upgraded Plan",
        sortable: true,
        value: "upgraded_plan",
        align: "left",
      },
      {
        text: "Months Subscribed",
        sortable: true,
        value: "months_subscribed",
        align: "left",
      },
      {
        text: "Points",
        sortable: true,
        value: "points_balance",
        align: "left",
      },
      {
        text: "Total Receipts",
        sortable: true,
        value: "total_receipts",
        align: "left",
      },
      {
        text: "Unpaid Invoices",
        sortable: true,
        value: "unpaid_invoices",
        align: "left",
      },
      {
        text: "Total Unpaid",
        sortable: true,
        value: "total_unpaid",
        align: "left",
      },
      {
        text: "Billing Usage Count",
        sortable: true,
        value: "billing_usage",
        align: "left",
      },
      {
        text: "Promo Cost",
        sortable: true,
        value: "promo_cost",
        align: "left",
      },
      {
        text: "Payment Method",
        sortable: true,
        value: "current_billing.ptype",
        align: "left",
      },
      {
        text: "Router Payment",
        sortable: true,
        value: "current_billing.router_ptype",
        align: "left",
      },
      {
        text: "Sign Up Date",
        sortable: true,
        value: "user.created_date",
        align: "left",
      },
      {
        text: "Order Date",
        sortable: true,
        value: "first_billing.created_date",
        align: "left",
      },
      {
        text: "Sales Lead Time",
        sortable: true,
        value: "sales_lead_time",
        align: "left",
      },
      {
        text: "Activation Date",
        sortable: true,
        value: "user.activation_date",
        align: "left",
      },
      {
        text: "ISP Lead Time",
        sortable: true,
        value: "lead_time",
        align: "left",
      },
      {
        text: "Contract Expiry Date",
        sortable: true,
        value: "contract_expiry",
        align: "left",
      },
      {
        text: "Promo Code",
        sortable: true,
        value: "promo.title",
        align: "left",
      },
      {
        text: "Promo is Active",
        sortable: true,
        value: "promo_valid",
        align: "left",
      },

      {
        text: "Vendor Name",
        sortable: true,
        value: "promo.vendor",
        align: "left",
      },
      {
        text: "Description",
        sortable: true,
        value: "promo.description",
        align: "left",
      },

      {
        text: "Promo Expense",
        sortable: true,
        value: "promo.promo_expense",
        align: "left",
      },
      {
        text: "qb_sales_term_ref",
        sortable: true,
        value: "promo.qb_sales_term_ref",
        align: "left",
      },
      {
        text: "promo_expense_months",
        sortable: true,
        value: "promo.promo_expense_months",
        align: "left",
      },
      {
        text: "promo_cc_price",
        sortable: true,
        value: "promo.promo_cc_price",
        align: "left",
      },
      {
        text: "promo_gcash_price",
        sortable: true,
        value: "promo.promo_price",
        align: "left",
      },
      {
        text: "promo_installation_price",
        sortable: true,
        value: "promo.promo_installation_price",
        align: "left",
      },
      {
        text: "promo_router_price",
        sortable: true,
        value: "promo.promo_router_price",
        align: "left",
      },
      {
        text: "promo_contract_length",
        sortable: true,
        value: "promo.promo_contract_length",
        align: "left",
      },
      {
        text: "promo_valid_length",
        sortable: true,
        value: "promo.promo_valid_length",
        align: "left",
      },
      {
        text: "contract_extend",
        sortable: true,
        value: "promo.contract_extend",
        align: "left",
      },
      {
        text: "Vendor ID",
        sortable: true,
        value: "promo.vendor_id",
        align: "left",
      },
      {
        text: "Promo Usage Limit",
        sortable: true,
        value: "promo.promo_usage_limit",
        align: "left",
      },
      {
        text: "Valid Till",
        sortable: true,
        value: "promo.valid_till",
        align: "left",
      },
      {
        text: "Upgrade Allowed",
        sortable: true,
        value: "promo.upgrade_allowed",
        align: "left",
      },
      {
        text: "Promo Type",
        sortable: true,
        value: "promo.promo_type",
        align: "left",
      },
      {
        text: "Promo Data",
        sortable: true,
        value: "promo.promo_data",
        align: "left",
      },
    ];
  }

  public promoHeaders = [
    {
      text: "Promo Code",
      sortable: true,
      value: "code",
      align: "left",
    },
    {
      text: "Type",
      sortable: true,
      value: "promo_type",
      align: "left",
    },
    {
      text: "Condo Building",
      sortable: true,
      value: "vendor",
      align: "left",
    },
    {
      text: "Description",
      sortable: true,
      value: "description",
      align: "left",
    },
    {
      text: "Service Name",
      sortable: true,
      value: "service_name",
      align: "left",
    },
    {
      text: "Active Usage Count",
      sortable: true,
      value: "active_usage",
      align: "left",
    },
    {
      text: "Total Usage Count",
      sortable: true,
      value: "total_usage_count",
      align: "left",
    },
    {
      text: "Usage Limit",
      sortable: true,
      value: "promo_usage_limit",
      align: "left",
    },
    {
      text: "Billing Usage Count",
      sortable: true,
      value: "billing_usage",
      align: "left",
    },
    {
      text: "Promo Receipts",
      sortable: true,
      value: "promo_receipts",
      align: "left",
    },
    {
      text: "Promo Cost",
      sortable: true,
      value: "promo_cost",
      align: "left",
    },
    {
      text: "Promo Length",
      sortable: true,
      value: "promo_valid_length",
      align: "left",
    },
    {
      text: "Promo Expiration Date",
      sortable: true,
      value: "valid_till",
      align: "left",
    },
    {
      text: "Is Active after Upgrade",
      sortable: true,
      value: "is_active_after_upgrade",
      align: "left",
    },
    {
      text: "Upgrade Allowed while Active",
      sortable: true,
      value: "upgrade_allowed",
      align: "left",
    },
  ];

  public search = "";
  public search2 = "";
  public users = [];
  public userCSV = [];
  public promoCSV = [];
  public promos = [];
  public user_id = 0;
  public scroll_item = "";
  public onlyActive = true;

  toggleOnlyActive() {
    this.onlyActive = !this.onlyActive;
  }
  stringify(item) {
    return JSON.stringify(item);
  }
  created() {
    if (location.host.startsWith("dev")) {
      document.title = "Dev: Sales Report";
    } else {
      document.title = "Sales Report";
    }
  }
  public async mounted() {
    const data = await dispatchSalesReport(this.$store);
    this.users = _.get(data, "data.users", []);
    this.promos = _.get(data, "data.promos", []);
    const userFields = this.headers().map((header) => header.value);
    this.userCSV = this.users.map((user) => {
      const item = {};
      console.log(user);
      this.headers().forEach((header) => {
        if (header.value === "sales_lead_time" && user.user.activation_date) {
          item[header.text] = this.diffDates(
            user.user.created_date,
            user.first_billing.created_date
          );
        } else if (header.value === "lead_time" && user.user.activation_date) {
          item[header.text] = this.diffDates(
            user.first_billing.created_date,
            user.user.activation_date
          );
        } else if (header.value === "promo.promo_data") {
          item[header.text] = this.stringify(user.promo);
        } else {
          item[header.text] = _.get(user, header.value, "");
        }
      });
      return item;
    });
    this.promoCSV = this.promos.map((promo) => {
      const item = {};
      this.promoHeaders.forEach((header) => {
        let value = _.get(promo, header.value, "");
        item[header.text] = value;
      });

      return item;
    });

    //console.log(this.promos);
    console.log("users", this.users);
  }

  diffDates(date1, date2) {
    return moment(date2).diff(moment(date1), "days");
  }
  formatDate(date) {
    if (date) {
      return moment
        .utc(date)
        .tz("Asia/Manila")
        .format("MMM DD, YYYY HH:mm:ss");
    }
    return "";
  }
  formatDateOnly(date) {
    if (date) {
      return moment
        .utc(date)
        .tz("Asia/Manila")
        .format("MMM DD, YYYY");
    }
    return "";
  }
  async editItem(user_id, scroll_item = null) {
    this.user_id = user_id;
    this.scroll_item = scroll_item;
  }
}
